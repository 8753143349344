export const imagesData = [
  {
    id: 0,
    imageUrl:
      "https://cdn.discordapp.com/attachments/979623779477123122/1052895289901592586/IMG-20220926-WA0000.jpg",
  },
  {
    id: 1,
    imageUrl:
      "https://cdn.discordapp.com/attachments/979623779477123122/1052896154754494464/FB_IMG_7204225090325431081.jpg",
  },
  {
    id: 2,
    imageUrl:
      "https://cdn.discordapp.com/attachments/979623779477123122/1053339187543277628/Snapchat-1772361236.jpg",
  },
  {
    id: 3,
    imageUrl:
      "https://cdn.discordapp.com/attachments/868016023020908554/1120082617459560459/20210322_181032.jpg",
  },
  {
    id: 4,
    imageUrl:
      "https://cdn.discordapp.com/attachments/868016023020908554/1120080542013063249/IMG_0498.jpg",
  },
  {
    id: 5,
    imageUrl:
      "https://cdn.discordapp.com/attachments/868016023020908554/1120081300645232710/IMG_0682.jpg",
  },
  {
    id: 6,
    imageUrl:
      "https://cdn.discordapp.com/attachments/868016023020908554/1120080917168410725/IMG_0691.jpg",
  },
  //asdsadd
  {
    id: 7,
    imageUrl:
      "https://cdn.discordapp.com/attachments/979623779477123122/1053339596412420096/IMG_3067.jpg",
  },
  {
    id: 8,
    imageUrl:
      "https://media.discordapp.net/attachments/979623779477123122/1053339351079211019/IMG-20220411-WA0032.jpg?width=514&height=686",
  },
  {
    id: 9,
    imageUrl:
      "https://cdn.discordapp.com/attachments/979623779477123122/1053339930924953611/Snapchat-55933594.jpg",
  },
];
