export const sideskillsdata = [
  {
    name: "Git",
    image: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
    description: "",
    remarks: "Version control with Git. Yes !",
  },

  {
    name: "Premiere Pro",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/5/58/Adobe_Premiere_Pro_CS6_Icon.png",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    remarks: "Video Editor ofc",
  },
  {
    name: "Blender",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/1200px-Blender_logo_no_text.svg.png",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    remarks: "3d Modelling , and I love it",
  },
  {
    name: "Unreal Engine",
    image:
      "https://download.logo.wine/logo/Unreal_Engine/Unreal_Engine-Logo.wine.png",
    description: "",
    remarks: "3d games with blueprint",
  },
  {
    name: "Unity",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/8/8a/Official_unity_logo.png",
    description: "",
    remarks: "First engine learnt",
  },
  {
    name: "Figma",
    image: "https://assets.stickpng.com/images/62c6bc0beee9410fe137d91e.png",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    remarks: "Frontend Designs Yes ofc",
  },
  {
    name: "Photoshop",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/640px-Adobe_Photoshop_CC_icon.svg.png",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    remarks: "It's a god tool ",
  },
];
