import React, { useEffect, useState } from 'react';

const Testfile = () => {

    return (
        <>
            <div className="wrapper">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="banner">
                <div className="content">
                    <h2>Animated <b>Background</b></h2>
                </div>
            </div>

        </>
    );
}

export default Testfile;
