
export const languagesData = [
    {
        name: 'C#',
        image: 'https://www.avenga.com/wp-content/uploads/2020/11/C-Sharp.png',
        description: '',
        remarks: 'Ofc I love it'
    },

    {
        name: 'JAVA',
        image: 'https://www.pngplay.com/wp-content/uploads/9/Java-Transparent-Images.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'Verbose Verbose Verbose'
    },
    {
        name: 'Javascript',
        image: 'https://www.freepnglogos.com/uploads/javascript-png/javascript-logo-transparent-logo-javascript-images-3.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'Master of all language IMO'
    },
    {
        name: 'Python',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Python_logo_and_wordmark.svg/1280px-Python_logo_and_wordmark.svg.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'GOD language'
    },
    {
        name: 'SQL',
        image: 'https://download.logo.wine/logo/MySQL/MySQL-Logo.wine.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'I really like it'
    },
    {
        name: 'PHP',
        image: 'https://pngimg.com/uploads/php/php_PNG34.png',

        remarks: "I don't understand why people hate it"
    },
    {
        name: 'C',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/C_Programming_Language.svg/1200px-C_Programming_Language.svg.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'MUST for everyone'
    },
    {
        name: 'MongoDB',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'A good noSQL db'
    },
    {
        name: 'HTML',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/768px-HTML5_logo_and_wordmark.svg.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'is it a programming language'
    },
    {
        name: 'CSS',
        image: 'https://www.freepnglogos.com/uploads/html5-logo-png/html5-logo-css-logo-png-transparent-svg-vector-bie-supply-9.png',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        remarks: 'Am I dumb to include it here?'

    },



];
